exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-christmas-wreaths-tsx": () => import("./../../../src/pages/christmas-wreaths.tsx" /* webpackChunkName: "component---src-pages-christmas-wreaths-tsx" */),
  "component---src-pages-funerals-tsx": () => import("./../../../src/pages/funerals.tsx" /* webpackChunkName: "component---src-pages-funerals-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sheen-mount-christmas-wreaths-tsx": () => import("./../../../src/pages/sheen-mount-christmas-wreaths.tsx" /* webpackChunkName: "component---src-pages-sheen-mount-christmas-wreaths-tsx" */),
  "component---src-pages-sheen-mount-tsx": () => import("./../../../src/pages/sheen-mount.tsx" /* webpackChunkName: "component---src-pages-sheen-mount-tsx" */),
  "component---src-pages-weddings-tsx": () => import("./../../../src/pages/weddings.tsx" /* webpackChunkName: "component---src-pages-weddings-tsx" */),
  "component---src-pages-workshops-tsx": () => import("./../../../src/pages/workshops.tsx" /* webpackChunkName: "component---src-pages-workshops-tsx" */)
}

